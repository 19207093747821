import config from '#config';
import { useCallback, useEffect, useState } from 'react';

type SubstitutionContent = {
  [key: string]: { title: string; subTitle: string };
};

type SubstitutionData = {
  title: string;
  subTitle?: string;
  text: string;
};

type Props = {
  subTitleStyle?: string;
  titleStyle: string;
  textStyle: string;
  content: SubstitutionContent;
  data: SubstitutionData;
  page: string;
};

const TitleSubstitution = ({
  content,
  data,
  subTitleStyle,
  titleStyle,
  textStyle,
  page,
}: Props) => {
  const [ptid, setPtid] = useState<string | null>();
  const [pdid, setPdid] = useState<string | null>();
  const [pParam, setPParam] = useState<string | null>();

  useEffect(() => {
    const originPage = sessionStorage.getItem('substitutionPage');
    if (page && (!originPage || originPage === page)) {
      sessionStorage.setItem('substitutionPage', page);
      const urlParams = new URLSearchParams(window.location.search);
      setPtid(urlParams.get('ptid'));
      setPdid(urlParams.get('pdid'));

      if (
        config.REFERRERS.some((ref) => document.referrer.includes(ref)) &&
        urlParams.get('t') === '1'
      ) {
        setPParam(urlParams.get('p'));
      }
    }
  }, [page]);

  const textModification = useCallback(
    (type: 'title' | 'subTitle') => {
      if (pParam && content[pParam]) {
        return content[pParam][type];
      }
    },
    [pParam],
  );

  return (
    <>
      <h1 className={titleStyle}>{textModification('title') || ptid || data.title}</h1>
      {data?.subTitle && <h2 className={subTitleStyle}>{data.subTitle}</h2>}
      <p className={textStyle}>{textModification('subTitle') || pdid || data.text}</p>
    </>
  );
};

export default TitleSubstitution;
